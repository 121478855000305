import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "../views/Home.vue";
import AllPosts from "../views/AllPosts.vue";
import PostDetails from "../views/PostDetails.vue";
import PrivacyPolicy from "../views/policies/PrivacyPolicy.vue";
import LegalPolicy from "../views/policies/LegalPolicy.vue";
import ContactPage from "../views/Contact.vue";
import InstitutePage from "../views/Institutes.vue";
import WorkflowPage from "../views/Workflow.vue";
import StructurePage from "../views/Structure.vue";
import TeamPage from "../views/Team.vue";

import Layout from "../views/Layout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "/Home",
    children: [
      {
        path: "home",
        name: "Home",
        component: HomePage,
      },
      {
        path: "posts/:category",
        name: "Posts",
        component: AllPosts,
      },
      {
        path: "post-details/:id",
        name: "PostDetails",
        component: PostDetails,
      },
      {
        path: "privacy-policy",
        name: "Privacy Policy",
        component: PrivacyPolicy,
      },
      {
        path: "legal-policy",
        name: "Legal Policy",
        component: LegalPolicy,
      },
      {
        path: "institutes",
        name: "institutes",
        component: InstitutePage,
      },
      {
        path: "contact",
        name: "contact",
        component: ContactPage,
      },
      {
        path: "workflow",
        name: "workflow",
        component: WorkflowPage,
      },
      {
        path: "structure",
        name: "structure",
        component: StructurePage,
      },
      {
        path: "team",
        name: "team",
        component: TeamPage,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
