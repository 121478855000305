<template>
  <div v-if="isLoading" class="loading-container">
    <!-- Your loading progress bar or message goes here -->
    <v-col cols="6" class="py-0">
      <v-img src="../assets/PlatformLogo-01.svg" class="loading-image"></v-img>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="black"
        class="loading-progress px-4"
      ></v-progress-linear>
    </v-col>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
  },
  data: function () {
    const lang = localStorage.getItem("lang") || "ar";

    return {
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
    };
  },
  watch: {
    isLoading(newVal) {
      // Scroll to top whenever loading status changes
      if (newVal) {
        this.scrollToTop();
      }
    },
  },
  mounted() {
    // Scroll to top when the component is mounted
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0); // Scrolls to the top instantly
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // This still works in modern browsers
        });
      }, 100); // Delay to allow the component to render before scrolling
    },
  },
};
</script>

<style scoped>
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.99);
  display: flex;
  flex-direction: column; /* Arrange items in a column */
  justify-content: center;
  align-items: center;
  z-index: 999; /* Make sure it's above other elements */
}

.loading-image {
  width: 100%;
}

.loading-title,
.loading-subtitle {
  margin: 0; /* Remove default margin */
}
.loading-progress {
  margin-top: 10px; /* Adjust the margin as needed */
}
</style>
