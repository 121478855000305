var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.allGovernmentArticle && _vm.allGovernmentArticle.length > 0)?_c('div',{staticClass:"white"},[_c('div',{staticClass:"page-container"},[_c('v-container',{staticClass:"py-3 pb-10",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"auto"}},[_c('img',{staticClass:"my-auto",attrs:{"src":require("../../assets/PlatformLogo-01.svg"),"width":"100","height":"auto"}}),_c('span',{staticClass:"fa-20 ml-2"},[_vm._v(" "+_vm._s(_vm.$t("section2.title")))])])],1),_c('v-row',{attrs:{"align-content":"stretch"}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-card',{staticClass:"main-card",attrs:{"elevation":"0"}},[_c('v-img',{staticClass:"mx-auto main-item-image mt-4 clickable",attrs:{"src":_vm.allGovernmentArticle[0].images[0].thumbnail_path},on:{"click":function($event){return _vm.postDetails(_vm.allGovernmentArticle[0])}}},[_c('div',{staticClass:"gradient-overlay"})]),_c('v-card-title',{staticClass:"px-0"},[(
                  _vm.language == 'en' &&
                  _vm.allGovernmentArticle[0].title_english != null
                )?_c('span',{staticClass:"white--dark text-truncate fa-20 clickable",on:{"click":function($event){return _vm.postDetails(_vm.allGovernmentArticle[0])}}},[_vm._v(_vm._s(_vm.allGovernmentArticle[0].title_english))]):_c('span',{staticClass:"white--dark text-truncate fa-20 clickable",on:{"click":function($event){return _vm.postDetails(_vm.allGovernmentArticle[0])}}},[_vm._v(_vm._s(_vm.allGovernmentArticle[0].title_arabic))])]),_c('div',{staticClass:"py-4"},[(_vm.language == 'en')?_c('span',{staticClass:"white--text px-4 py-1 dark-panel-1 fa-14"},[_vm._v(_vm._s(_vm.allGovernmentArticle[0].category_name_english))]):_c('span',{staticClass:"white--text px-4 py-1 dark-panel-1 fa-14"},[_vm._v(_vm._s(_vm.allGovernmentArticle[0].category_name_arabic))])]),_c('v-card-title',{staticClass:"pa-0"},[(
                  _vm.language == 'en' &&
                  _vm.allGovernmentArticle[0].content_english != null
                )?_c('span',{staticClass:"white--dark text-truncate-two-lines fa-12"},[_vm._v(_vm._s(_vm.allGovernmentArticle[0].content_english))]):_c('span',{staticClass:"white--dark text-truncate-two-lines fa-12"},[_vm._v(_vm._s(_vm.allGovernmentArticle[0].content_arabic))])]),_c('v-row',{staticClass:"mt-auto py-3"},[_c('v-col',{staticClass:"align-self-end fa-10 py-0",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"white--dark",attrs:{"small":""}},[_vm._v("mdi-calendar-account-outline")]),_c('span',{staticClass:"white--dark mx-2"},[_vm._v(_vm._s(_vm.formatDate(_vm.allGovernmentArticle[0].created_at)))])],1),_c('v-col',{staticClass:"align-self-end fa-12 py-0",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"white--dark",attrs:{"small":""}},[_vm._v("mdi-eye-outline")]),_c('span',{staticClass:"white--dark mx-2"},[_vm._v(_vm._s(_vm.allGovernmentArticle[0].views_count))])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.allGovernmentArticle && _vm.allGovernmentArticle.length > 0)?_c('v-row',_vm._l((_vm.allGovernmentArticle.slice(1, 6)),function(item,index){return _c('v-col',{key:index,staticClass:"mb-2 px-2",attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('v-img',{staticClass:"mx-auto main-item-image clickable",attrs:{"src":item.images && item.images.length > 0
                        ? item.images[0].thumbnail_path
                        : require('@/assets/No-Image-Placeholder.png'),"width":"auto%","height":"8em"},on:{"click":function($event){return _vm.postDetails(item)}}},[_c('div',{staticClass:"gradient-overlay"})])],1),_c('v-col',{staticClass:"pt-0 my-auto",attrs:{"cols":"8"}},[(_vm.language == 'en')?_c('span',{staticClass:"white--text px-4 dark-panel-1 fa-12"},[_vm._v(_vm._s(item.category_name_english))]):_c('span',{staticClass:"white--text px-4 dark-panel-1 fa-12"},[_vm._v(_vm._s(item.category_name_arabic))]),(_vm.language == 'en' && item.title_english != null)?_c('v-card-title',{staticClass:"dark--text fa-16 text-truncate pb-0 pt-1 px-0"},[_vm._v(_vm._s(item.title_english))]):_c('v-card-title',{staticClass:"dark--text fa-16 text-truncate pb-0 pt-1 px-0"},[_vm._v(_vm._s(item.title_arabic))]),_c('v-card-text',{staticClass:"align-self-end fa-10 py-0 px-0",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"white--dark",attrs:{"small":""}},[_vm._v("mdi-calendar-account-outline")]),_c('span',{staticClass:"white--dark fa-10 mx-2"},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])],1),_c('v-card-text',{staticClass:"align-self-end fa-12 py-0 px-0",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"white--dark",attrs:{"small":""}},[_vm._v("mdi-eye-outline")]),_c('span',{staticClass:"white--dark mx-2"},[_vm._v(_vm._s(item.views_count))])],1)],1)],1)],1)}),1):_vm._e()],1)],1)],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }